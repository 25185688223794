.home-container {
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 0;

    @mixin media {
        grid-template-columns: 100%;
    } 
}
.home-left {
    grid-column: 1;
    border-right: 1px dashed white;
}
.home-right {
    grid-column: 2;

    @mixin media {
        grid-column: 1;
    } 
}

.carousel {
    // margin-top: $lh / 2;
    margin-bottom: 0;
    padding: $lh;
}

.carousel-cell {
    //background: blue;
    // width: 33.3%;
    width: 100%;
    // background: red;
    // margin-right: $lh;

    // @media only screen and (max-width: 1300px) {
    //     width: 33.3%;
    // }

    // @mixin media {
    //     width: 100%;
    // }

}

.dot {
    background: white !important;
}

.is-selected {

}

// Responsive image pattern

.image-container {
    width: 100%;
    position: relative;
    //background: $lightgrey;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.image-container-after {
    content: '';
    width: 100%;
    //padding-bottom: 80%;
    display: block;
    height: 0;
    width: 100%;
}

.image-full-height {
    height: 100%;
}

.image-full-height-absolute {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0
}

.carousel-caption {
    // position: absolute;
    font-size: 0.8em;
    padding-top: $lh / 2;
    // background: red;
}