// @font-face {font-family: 'Gunkan';src: url('../fonts/IBMPlexMono-Regular.woff2') format('woff');}
@font-face {font-family: 'Gunkan';src: url('../fonts/FavoritMonoStd-Book.woff2') format('woff');}

* {
    box-sizing: border-box;
}

body {
    color: white;
    background: #292929;
    //background: #1d08d8;
    font-family: Gunkan, Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 1.38;
    margin: 0;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;

    @mixin media {
        font-size: 15px;
    } 

    @media only screen and (min-width: 1500px) {
        //font-size: 1.5vw;
    }
}

html {
    scroll-behavior: smooth;
  }